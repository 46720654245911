<!DOCTYPE html>
<html>
<head>
  <meta charset="UTF-8">
  <title>403 - Access Forbidden</title>
  <link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.6/css/bootstrap.min.css">
  <script src="https://code.jquery.com/jquery-1.11.3.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.6/js/bootstrap.min.js"></script>
  <style>
    .oops {
      font-size: 9em;
      letter-spacing: 2px;
    }
    .message {
      font-size: 3em;
    }
  </style>
</head>
<body>
  <div class="container text-center">
    <div class="row vcenter" style="margin-top: 20%;">
      <div class="col-md-12">
        <div class="error-template">
          <h1 class="oops">Oops!</h1>
          <h2 class="message">404 Page not found</h2>
          <div class="error-details">
            Sorry, you do not have access to this page, please contact your administrator.
            <a [routerLink]="'/main/modules/show'">Go to home</a>
          </div>
        </div>
      </div>
    </div>
</div>

</body>
</html>
